<template>
    <div class="item">
        <a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch?code=14018102000148" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
        <img src="@/assets/images/beian.png" style="float:left;" width="20" height="20"/>
        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">晋公网安备14018102000148</p></a>
        </div>
</template>
<script>

</script>
<style scoped>
.item{
    width: 100%; /* 宽度占满整个页面 */
    background-color: rgba(50, 50, 50, 0.7); /* 半透明背景 */
    color: white; /* 字体颜色 */
    padding: 10px; /* 内边距 */
    text-align: center; /* 内容居中 */
  }
</style>